import Swiper from 'swiper';

$(function () {
    const swiper = new Swiper('.swiper-container', {
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        effect: "fade",
        loop: true,
        simulateTouch: true
    });
    $('.stopbtn').on('click', function () {
        const _class = $(this).attr('class');
        if ($(this).hasClass('stop')) {
            $(this).addClass('start')
                .removeClass('stop');
            swiper.autoplay.stop();
        } else {
            $(this).addClass('stop')
                .removeClass('start');
            swiper.autoplay.start();
        }
    }).keypress(function (e) {
        let keycode = event.keyCode ? event.keyCode : event.which;
        if (13 == keycode) {
            $(this).click();
        }
    });
})
